import React from "react";
import { FontColor, FontSize, FontWeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { Text, Icon } from "@mbank-design/design-system/components";
import { Link } from "react-router-dom";
import { IconColor } from "@mbank-design/design-system/enums";
import { ChevronLeft, PhoneHandle } from "@mbank-design/design-system/icons";
import "./index.scss";
import { Component } from "react";
import PropTypes from "prop-types";
import { handleClickFastCall } from "../FastButtons";

class NavBarSubLinks extends Component {
  /**
   * @type {{ pageLinks: {name: string, link: string}[], vehicleTypeLinks: {name: string, link: string}[]}}
   */
  state = {
    pageLinks: [
      {
        name: "nowe",
        link: "/nowe-samochody"
      },
      {
        name: "poleasingowe",
        link: "/samochody-poleasingowe"
      },
      {
        name: "elektryczne",
        link: "/samochody-elektryczne"
      }
    ],
    vehicleTypeLinks: [
      {
        name: "sedan",
        link: "/sedan"
      },
      {
        name: "hatchback",
        link: "/hatchback"
      },
      {
        name: "SUV",
        link: "/suv"
      }
    ],

    financingLinks: [
      {
        name: "najem i leasing",
        link: "/finansowanie"
      },
      {
        name: 'program "mój elektryk"',
        link: "/moj-elektryk"
      },
      {
        name: "elekryfikacja floty",
        link: "/elektryfikacja-floty"
      }
    ]
  };
  static propTypes = {
    vehicleBrandLinks: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string
      })
    ),
    offerSublinksExpanded: PropTypes.bool,
    brandSublinksExpanded: PropTypes.bool,
    isMobile: PropTypes.bool,
    toggleBrandSublinksExpansion: PropTypes.func,
    toggleOfferSublinksExpansion: PropTypes.func,
    collapseSublinks: PropTypes.func
  };

  removeUnavailableMakesFromFilters() {
    return this.props.offersFiltersDataFromApi.MakesAndModels.filter(makeModel => makeModel.Make.Count > 0);
  }

  splitVehicleBrandLinks(links){
    let splitedVehicleBrandLinks=[]
    let copyLinks=JSON.parse(JSON.stringify(links))

    const amount1= copyLinks.length
    const column1=Math.ceil(amount1/4)
    splitedVehicleBrandLinks.push(copyLinks.splice(0,column1))
    
    const amount2= amount1-column1
    const column2=Math.ceil(amount2/3)
    splitedVehicleBrandLinks.push(copyLinks.splice(0,column2))
    
    const amount3= amount2-column2
    const column3=Math.ceil(amount3/2)
    splitedVehicleBrandLinks.push(copyLinks.splice(0,column3))
    
    const amount4= amount3-column3
    const column4= amount4
    splitedVehicleBrandLinks.push(copyLinks.splice(0,column4))

    return splitedVehicleBrandLinks;
  }

  clearFiltersFromOutside = () => {
    const clearFiltersButton = document.getElementById("clear-all-filters");
    if(clearFiltersButton){
      clearFiltersButton.click();
    }
    return
  };


  render() {
    let pageLinks = this.state.pageLinks;
    let vehicleTypeLinks = this.state.vehicleTypeLinks;
    let financingLinks = this.state.financingLinks;
    let vehicleBrandLinks = this.props.vehicleBrandLinks;
    let offerSublinksExpanded = this.props.offerSublinksExpanded;
    let brandSublinksExpanded = this.props.brandSublinksExpanded;
    let vehicleBrandLinksSplitted = this.splitVehicleBrandLinks(this.props.vehicleBrandLinks);

    return (
      <>
        {/* offers tab*/}
        {offerSublinksExpanded && (
          <>
            {this.props.isMobile && (
              <div
                className="clickable as-link return-button-container"
                onClick={() => this.props.toggleOfferSublinksExpansion()}
              >
                <div className="centered">
                  <Icon iconComponent={ChevronLeft} primaryColor={IconColor.SECONDARY_CONTRAST} />
                </div>
                <Text
                  as="h3"
                  className="flex-start"
                  marginLeft={16}
                  fontWeight={FontWeight.WEIGHT_600}
                >
                  oferta
                </Text>
              </div>
            )}
            <div className="offer-sublinks">
              <div className="sublinks-column">
                <div className="sublinks-column-header">
                  <Text
                    as="p"
                    className="flex-start"
                    marginRight={30}
                    marginVertical={16}
                    fontWeight={FontWeight.WEIGHT_600}
                    fontSize={FontSize.SIZE_20}
                  >
                    samochody
                  </Text>
                  {this.props.isMobile && (
                    <Link
                      to={"/lista-pojazdow"}
                      onClick={() => {this.props.collapseSublinks(); this.clearFiltersFromOutside()}}
                      className="sublinks-column__link"
                    >
                      <Text
                        as="p"
                        className={
                          "centered sublinks-column__text" +
                          (window.location.href.includes("/samochody-poleasingowe") ? "highlighted" : "")
                        }
                        marginRight={30}
                        fontWeight={FontWeight.WEIGHT_600}
                        color={FontColor.endeavourBlueUI}
                      >
                        {"wszystkie"}
                      </Text>
                    </Link>
                  )}
                </div>
                {pageLinks.map((pageLink) => {
                  return (
                    <Link to={pageLink.link} onClick={() => this.props.collapseSublinks()}>
                      <Text
                        as="p"
                        className={"centered " + (window.location.href.includes(pageLink.link) ? "highlighted" : "")}
                        marginRight={30}
                        fontWeight={FontWeight.WEIGHT_500}
                        color={FontColor.DARK}
                      >
                        {pageLink.name}
                      </Text>
                    </Link>
                  );
                })}
                {!this.props.isMobile && (
                  <Link to={"/lista-pojazdow"} onClick={() => {this.props.collapseSublinks(); this.clearFiltersFromOutside()}} className='offer-sublinks__show-all-link'>
                    <Text
                      as="p"
                      className={
                        "centered " + (window.location.href.includes("/samochody-poleasingowe") ? "highlighted" : "")
                      }
                      marginRight={30}
                      fontWeight={FontWeight.WEIGHT_600}
                      color={FontColor.endeavourBlueUI}
                    >
                      {"wszystkie"}
                    </Text>
                  </Link>
                )}
              </div>
              <div className="sublinks-column">
                <div className="sublinks-column-header">
                  <Text
                    as="p"
                    className="flex-start"
                    marginRight={30}
                    marginVertical={16}
                    fontWeight={FontWeight.WEIGHT_600}
                    fontSize={FontSize.SIZE_20}
                  >
                    nadwozia
                  </Text>
                  {this.props.isMobile && (
                    <Link
                      to={"/lista-pojazdow"}
                      onClick={() => {this.props.collapseSublinks(); this.clearFiltersFromOutside()}}
                      className="sublinks-column__link"
                    >
                      <Text
                        as="p"
                        className={
                          "centered sublinks-column__text" +
                          (window.location.href.includes("/samochody-poleasingowe") ? "highlighted" : "")
                        }
                        marginRight={30}
                        fontWeight={FontWeight.WEIGHT_600}
                        color={FontColor.endeavourBlueUI}
                      >
                        {"wszystkie"}
                      </Text>
                    </Link>
                  )}
                </div>

                {vehicleTypeLinks.map((vehicleTypeLink) => {
                  return (
                    <Link to={vehicleTypeLink.link} onClick={() => this.props.collapseSublinks()}>
                      <Text
                        as="p"
                        className={
                          "centered " + (window.location.href.includes(vehicleTypeLink.link) ? "highlighted" : "")
                        }
                        marginRight={30}
                        fontWeight={FontWeight.WEIGHT_500}
                        color={FontColor.DARK}
                      >
                        {vehicleTypeLink.name}
                      </Text>
                    </Link>
                  );
                })}
                {!this.props.isMobile && (
                  <Link to={"/lista-pojazdow"} onClick={() => {this.props.collapseSublinks(); this.clearFiltersFromOutside()}} className='offer-sublinks__show-all-link'>
                    <Text
                      as="p"
                      className={
                        "centered " + (window.location.href.includes("/samochody-poleasingowe") ? "highlighted" : "")
                      }
                      marginRight={30}
                      fontWeight={FontWeight.WEIGHT_600}
                      color={FontColor.endeavourBlueUI}
                    >
                      {"wszystkie"}
                    </Text>
                  </Link>
                )}
              </div>
              <div className="sublinks-column">
                <div className="sublinks-column-header">
                  <Text
                    as="p"
                    className="flex-start"
                    marginRight={30}
                    marginVertical={16}
                    fontWeight={FontWeight.WEIGHT_600}
                    fontSize={FontSize.SIZE_20}
                  >
                    finansowanie
                  </Text>
                </div>

                {financingLinks.map((financingLink) => {
                  return (
                    <Link to={financingLink.link} onClick={() => this.props.collapseSublinks()}>
                      <Text
                        as="p"
                        className={
                          "centered " + (window.location.href.includes(financingLink.link) ? "highlighted" : "")
                        }
                        marginRight={30}
                        fontWeight={FontWeight.WEIGHT_500}
                        color={FontColor.DARK}
                      >
                        {financingLink.name}
                      </Text>
                    </Link>
                  );
                })}
              </div>
            </div>
          </>
        )}

        {/* brands tab*/}
        {brandSublinksExpanded && (
          <>
            {this.props.isMobile && (
              <div
                className="clickable as-link return-button-container"
                onClick={() => this.props.toggleBrandSublinksExpansion()}
              >
                <div className="centered">
                  <Icon iconComponent={ChevronLeft} primaryColor={IconColor.SECONDARY_CONTRAST} />
                </div>
                <Text as="h3" className="flex-start" marginLeft={16} fontWeight={FontWeight.WEIGHT_600}>
                  marki
                </Text>
              </div>
            )}
            <div className="brand-sublinks">
              {vehicleBrandLinksSplitted.map((column) => (
                <div className="column">
                  {column.map((vehicle) => (
                    <Link to={vehicle.link} onClick={() => this.props.collapseSublinks()}>
                      <Text
                        as="p"
                        className={window.location.href.includes(vehicle.link) ? "highlighted" : ""}
                        marginRight={30}
                        styledAs={"body"}
                        color={FontColor.DARK}
                      >
                        {vehicle.name}
                      </Text>
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
      </>
    );
  }
}

export default NavBarSubLinks;
